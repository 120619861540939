<template>
  <Animated name="pulse" appear duration="1200">
    <div class="">
      <sequential-entrance fromTop>
        <SellerHeader
          to="/seller"
          headerText="Estadísticas"
          :superAdmin="true"
        />
      </sequential-entrance>

      <div class="mt-5 p-3">
        <div>
          <v-row class="mt-5">
            <v-col cols="12" class="pt-0">
              <v-select
                item-text="name"
                item-value="id"
                v-model="searchBy"
                :items="searchOptions"
                label="Buscar por"
                rounded
                color="#00B8D9"
                background-color="#F4F5F7"
                class="app-input__select pt-0"
              ></v-select>
            </v-col>
          </v-row>

          <v-row class="mt-0" v-if="searchBy === 'event_id'">
            <v-col cols="12" class="pt-0">
              <v-select
                :item-text="item => `${item.id} - ${item.name}`"
                item-value="id"
                v-model="event_id"
                :items="events"
                label="Selecciona un evento"
                rounded
                color="#00B8D9"
                background-color="#F4F5F7"
                class="app-input__select pt-0"
              ></v-select>
            </v-col>
          </v-row>

          <v-row class="mt-0" v-if="searchBy === 'business_id'">
            <v-col cols="12" class="pt-0">
              <v-select
                item-text="name"
                item-value="id"
                v-model="business_id"
                :items="business"
                label="Selecciona un cliente"
                rounded
                color="#00B8D9"
                background-color="#F4F5F7"
                class="app-input__select pt-0"
              ></v-select>
            </v-col>
          </v-row>
        </div>

        <v-row class="mt-0">
          <v-col cols="6" class="pt-0">
            <v-flex>
              <v-menu
                v-model="menuFrom"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateFrom"
                    label="Fecha desde"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateFrom"
                  @input="menuFrom = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-col>

          <v-col cols="6" class="pt-0">
            <v-flex>
              <v-menu
                v-model="menuTo"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateTo"
                    label="Fecha hasta"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateTo"
                  @input="menuTo = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="d-flex justify-center mt-2 pt-1">
            <v-btn
              class="app-btn__primary px-8"
              color="#1E87FD"
              @click="getStats()"
              rounded
              :disabled="isDisabled"
              >Enviar</v-btn
            >
          </v-col>
        </v-row>
        <!-- Skeleton Loader -->
        <v-container v-if="loading">
          <b-skeleton width="100%" height="28px"></b-skeleton>
          <b-skeleton width="100%" height="28px"></b-skeleton>
          <b-skeleton width="100%" height="28px"></b-skeleton>
          <b-skeleton width="100%" height="28px"></b-skeleton>
        </v-container>

        <table class="table mt-5" v-if="!loading && stats">
          <tbody>
            <tr>
              <th scope="row">Tickets vendidos</th>
              <td>{{ stats.tickets_quota }}</td>
            </tr>
            <tr>
              <th scope="row">Total recaudado</th>
              <td>${{ formatPrice(stats.total_collected) }}</td>
            </tr>
            <tr>
              <th scope="row">Porcentaje de ocupación</th>
              <td>{{ stats.occupation_percentage.toFixed(2) }}%</td>
            </tr>
            <tr>
              <th scope="row">Cupos totales de productos</th>
              <td>{{ stats.products_quote }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Animated>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import {
  getAllEventsSimplified,
  getAllBusiness,
  getTicketStatsSuperAdmin,
} from '@/services/seller.service'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import { getMyData } from '@/services/user.service'
import { ErrorMessages } from '@/helpers/constants'

export default {
  data() {
    return {
      user: null,
      loading: false,
      business: null,
      searchBy: 'all',
      event_id: null,
      business_id: null,
      events: null,
      searchOptions: [
        { id: 'business_id', name: 'Business' },
        { id: 'event_id', name: 'Evento' },
        { id: 'all', name: 'Todos' },
      ],
      menuFrom: null,
      dateFrom: null,
      menuTo: null,
      dateTo: null,
      stats: null,
    }
  },
  computed: {
    isDisabled() {
      if (this.searchBy && this.dateFrom && this.dateTo) {
        if (this.searchBy === 'event_id' && this.event_id) {
          return false
        } else if (this.searchBy === 'business_id' && this.business_id) {
          return false
        } else if (this.searchBy === 'all') {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
  },
  methods: {
    async getMyData() {
      try {
        const { data } = await getMyData()
        this.user = data

        if (this.user.role_id !== 7) {
          Vue.$toast.error(`No tienes permiso para acceder a esta página`)
          setTimeout(() => {
            this.$router.push('/seller')
          }, 2000)
        } else {
          this.getAllBusiness()
          this.getAllEvents()
        }
      } catch (e) {
        console.error(e)
      }
    },
    async getAllBusiness() {
      const { data } = await getAllBusiness()
      this.business = data.data
    },

    async getAllEvents() {
      const { data } = await getAllEventsSimplified()
      this.events = data.data
    },
    async getStats() {
      this.loading = true
      const event = this.searchBy === 'event_id' ? this.event_id : null
      const business = this.searchBy === 'business_id' ? this.business_id : null

      const response = await getTicketStatsSuperAdmin(
        event,
        business,
        this.dateFrom,
        this.dateTo
      )

      this.stats = response.data
      this.loading = false
    },
    formatPrice(value) {
      const val = (value / 1).toFixed().replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
  },
  mounted() {
    this.getMyData()
  },
  components: {
    SellerHeader,
  },
}
</script>

<style lang="scss" scope></style>
